import { styled } from "@mui/material";
import MuiTypography from "@mui/material/Typography";

const Typography = styled(MuiTypography)(({ theme }) => ({
  variants: [
    {
      props: { variant: "Caption" },
      style: {
        color: theme.palette.primary.main,
        fontSize: "10px",
        lineHeight: "16px",
      },
    },
    {
      props: { variant: "TextXS" },
      style: {
        color: theme.palette.primary.main,
        fontSize: "12px",
        lineHeight: "18px",
      },
    },
    {
      props: { variant: "TextS" },
      style: {
        color: theme.palette.primary.main,
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    {
      props: { variant: "TextM" },
      style: {
        color: theme.palette.primary.main,
        fontSize: "16px",
        lineHeight: "22px",
      },
    },
    {
      props: { variant: "TextL" },
      style: {
        color: theme.palette.primary.main,
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    {
      props: { variant: "HeadingXS" },
      style: {
        color: theme.palette.primary.main,
        fontSize: "24px",
        lineHeight: "30px",
        fontWeight: 500,
      },
    },
  ],
}));

export type TypographyProps = typeof Typography extends (props: infer P) => any ? P : never;

export default Typography;
