import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SmsCodeInput from "./SmsCodeInput/SmsCodeInput";
import { Collapse } from "@mui/material";
import LoginForm from "./LoginForm/LoginForm";
import useLoginHandlers from "./hooks/useLoginHandlers";
import Div from "library/primitives/Div";
import MicrosoftButton from "pages/Settings/SettingsConnectedAccounts/components/MicrosoftButton";
import Typography from "library/Typography";
import { useCallback, useReducer } from "react";
import Dialog from "library/Dialog";
import { Trans, useTranslation } from "react-i18next";
import Button from "components/Button";
import Span from "library/primitives/Span";
import ThemeProvider2024 from "library/MuiThemeProvider/ThemeProvider2024";

/**
 * @param {{ setError(error: null | Error): void; error: null | Error }} props
 */
function Login(props) {
  const { t } = useTranslation();
  const {
    clearError,
    showMfa,
    onSignIn,
    onMfaCancel,
    onVerifyMfaCode,
    requestVerificationCode,
    mfaHint,
    onSignInWithMicrosoft,
  } = useLoginHandlers({
    setError: props.setError,
  });

  const [showMicrosoftNotLinkedDialog, toggleShowMicrosoftNotLinkedDialog] = useReducer((prev) => !prev, false);

  const handleSignInWithMicrosoft = useCallback(async () => {
    try {
      await onSignInWithMicrosoft();
    } catch (error) {
      if (error.code === "auth/admin-restricted-operation") {
        toggleShowMicrosoftNotLinkedDialog(true);
      } else {
        props.setError(error);
      }
    }
  }, [onSignInWithMicrosoft, props]);

  return (
    <>
      <Box bgcolor="primary.main">
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Stack>
            <Collapse in={!showMfa} unmountOnExit={false}>
              <LoginForm onSignIn={onSignIn} errorMessage={props.error?.message} clearErrors={clearError} />
            </Collapse>
            <Collapse in={showMfa} unmountOnExit>
              <SmsCodeInput
                verifyCode={onVerifyMfaCode}
                resendCode={requestVerificationCode}
                onCancel={onMfaCancel}
                hint={mfaHint}
                errorMessage={props.error?.message}
              />
            </Collapse>
            <ThemeProvider2024>
              <Typography variant="TextM" sx={{ color: "common.white", p: 1 }} textAlign="center">
                or
              </Typography>
              <MicrosoftButton onClick={handleSignInWithMicrosoft}>{t("Login.microsoftButtonText")}</MicrosoftButton>
            </ThemeProvider2024>
            <Div id="recaptcha-mfa-signin-container" sx={{ display: "none" }} />
          </Stack>
        </Box>
      </Box>
      <Dialog
        id="auth-error-dialog"
        severity="error"
        open={showMicrosoftNotLinkedDialog}
        onClose={toggleShowMicrosoftNotLinkedDialog}
        maxWidth={240}
        title={t("Login.error.microsoft.title")}
        subTitle={t("Login.error.microsoft.subTitle")}
        text={
          <Trans i18nKey="Login.error.microsoft.text">
            Not bold <Span sx={{ fontWeight: 500 }}>Bold</Span>.
          </Trans>
        }
        cancelButton={
          <Button onClick={toggleShowMicrosoftNotLinkedDialog} sx={{ "&.MuiButton-sizeLarge": { width: "100%" } }}>
            {t("okButtonText")}
          </Button>
        }
      />
    </>
  );
}
export default Login;
