import { styled } from "@mui/material";
import MuiDialogActions from "@mui/material/DialogActions";

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0),
  "& > :not(style)~:not(style)": {
    marginLeft: 0,
  },
  "& > :last-of-type:first-of-type": {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  "& > :last-of-type:not(:first-of-type)": {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
}));
export default DialogActions;
