/**
 * Design System Component: Button
 */

import type { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import Loader from "components/LoaderDots";
import { styled } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { forwardRef } from "react";

export interface ButtonProps
  extends Pick<
    MuiButtonProps,
    | "disabled"
    | "sx"
    | "fullWidth"
    | "href"
    | "size"
    | "onClick"
    | "children"
    | "startIcon"
    | "endIcon"
    | "type"
    | "form"
    | "id"
    | "tabIndex"
    | "component"
  > {
  loading?: boolean;
  variant?: "primary" | "secondary" | "tertiary" | "text" | "danger" | "link";
}

const Button = styled(
  forwardRef<any, ButtonProps>((props: ButtonProps, ref) => {
    const { variant = "primary", children, loading = false, startIcon, endIcon, ...rest } = props;

    return (
      <MuiButton
        ref={ref}
        startIcon={loading ? null : startIcon}
        endIcon={loading ? null : endIcon}
        disabled={loading}
        disableElevation
        disableFocusRipple
        variant={variant}
        {...rest}
      >
        {loading ? <Loader size={rest.size} /> : children}
      </MuiButton>
    );
  })
)(({ theme }) => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  whiteSpace: "nowrap",
  borderRadius: 999,
  transition: "all 100ms ease-in-out",
  textTransform: "unset",
  minWidth: theme.spacing(4),
  cursor: "pointer",

  // Sizing
  "&.MuiButton-sizeMedium": {
    padding: theme.spacing(1.75, 3),
    gap: theme.spacing(1),
    height: theme.spacing(6),
  },

  "&.MuiButton-sizeSmall": {
    padding: theme.spacing(1, 2.5),
    gap: theme.spacing(0.75),
    fontSize: "14px",
    height: theme.spacing(4.5),
  },

  "&.MuiButton-sizeLarge": {
    width: theme.spacing(24),
    padding: theme.spacing(1.75, 3),
    gap: theme.spacing(1),
    height: theme.spacing(6),
  },

  "& .MuiButton-startIcon": {
    marginRight: 0,
  },

  "& .MuiButton-endIcon": {
    marginLeft: 0,
  },

  // Variants
  "&.MuiButton-primary": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      color: theme.palette.secondary1[60],
    },

    "&:disabled": {
      opacity: 0.3,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[60],
    },

    "&:focus-visible": {
      outline: `2px solid ${theme.palette.secondary1[60]}`,
    },

    "&:active": {
      color: theme.palette.secondary1[60],
    },
  },

  "&.MuiButton-secondary": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    border: `2px solid ${theme.palette.primary.main}`,

    "&:hover": {
      backgroundColor: theme.palette.secondary1[10],
    },

    "&:disabled": {
      opacity: 0.3,
    },

    "&:focus-visible": {
      outline: `2px solid ${theme.palette.secondary1[100]}`,
    },

    "&:active": {
      backgroundColor: theme.palette.secondary1[10],
    },
  },

  "&.MuiButton-tertiary": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[20],

    "&:hover": {
      backgroundColor: theme.palette.grey[60],
    },

    "&:disabled": {
      opacity: 0.3,
      backgroundColor: theme.palette.grey[20],
    },

    "&:focus-visible": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[20],
      outline: `2px solid ${theme.palette.primary.main}`,
    },

    "&:active": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[60],
    },
  },

  "&.MuiButton-text": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    borderRadius: theme.spacing(0.5),

    // Overrides default padding for text buttons (size small and medium)
    padding: theme.spacing(0.5, 1),
    height: "auto",

    "&:hover": {
      color: theme.palette.grey[100],
    },

    "&:disabled": {
      color: theme.palette.grey[60],
    },

    "&:focus-visible": {
      outline: `2px solid ${theme.palette.primary.main}`,
    },

    "&:active": {
      color: theme.palette.grey[100],
    },
  },

  "&.MuiButton-danger": {
    color: theme.palette.error.main,
    border: `2px solid ${theme.palette.error.main}`,

    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },

    "&:disabled": {
      opacity: 0.3,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },

    "&:focus-visible": {
      outline: `2px solid ${theme.palette.primary.main}`,
    },

    "&:active": {
      backgroundColor: theme.palette.error.light,
    },
  },

  "&.MuiButton-link": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    borderRadius: theme.spacing(0.5),

    // Overrides default padding for text buttons (size small and medium)
    padding: theme.spacing(0.5, 1),
    height: "auto",

    "&.MuiButton-sizeSmall": {
      gap: "0px",
    },

    "&:hover": {
      backgroundColor: theme.palette.grey[20],
    },

    "&:disabled": {
      color: theme.palette.grey[60],
    },

    "&:focus-visible": {
      outline: `2px solid ${theme.palette.primary.main}`,
    },

    "&:active": {
      color: theme.palette.grey[100],
    },
  },
}));

export default Button;
