import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import Button from "components/Button";

interface SubmitAndCancelButtonsProps {
  submitText: string;
  submitDisabled: boolean;
  onCancel: () => void;
}

function SubmitAndCancelButtons(props: SubmitAndCancelButtonsProps) {
  const { submitText, submitDisabled, onCancel } = props;
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Button size="small" variant="tertiary" type="reset" onClick={onCancel} fullWidth>
          {t("cancelButtonText")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button size="small" type="submit" disabled={submitDisabled} fullWidth>
          {submitText}
        </Button>
      </Grid>
    </Grid>
  );
}
export default SubmitAndCancelButtons;
