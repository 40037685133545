import { styled } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Button from "components/Button";
import { ButtonProps } from "components/Button/Button";

const StyledMicrosoftButton = styled(Button)(({ theme }) => ({
  width: "unset",
  "&.MuiButton-sizeLarge": {
    width: "unset",
  },
  "&.MuiButton-secondary": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[60]}`,

    "&:hover": {
      backgroundColor: theme.palette.grey[40],
    },

    "&:disabled": {
      opacity: 0.3,
    },

    "&:focus-visible": {
      outline: `1px solid ${theme.palette.secondary1[100]}`,
      border: `1px solid ${theme.palette.secondary1[100]}`,
    },

    "&:active": {
      borderColor: theme.palette.grey[60],
      backgroundColor: theme.palette.grey[60],
    },
  },
}));

export const MicrosoftIcon = () => (
  <SvgIcon>
    <path d="M11.4285 1.14282H1.14282V11.4285H11.4285V1.14282Z" fill="#F25022" />
    <path d="M11.4285 12.5714H1.14282V22.8571H11.4285V12.5714Z" fill="#00A4EF" />
    <path d="M21.8572 1.14282H11.5715V11.4285H21.8572V1.14282Z" fill="#7FBA00" />
    <path d="M23.2857 13H13V23.2857H23.2857V13Z" fill="#FFB900" />
  </SvgIcon>
);

function MicrosoftButton(props: Omit<ButtonProps, "variant" | "startIcon">) {
  return <StyledMicrosoftButton {...props} variant="secondary" startIcon={<MicrosoftIcon />} />;
}

export default MicrosoftButton;
