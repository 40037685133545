import { styled } from "@mui/material";
import MuiDialogContentText, { DialogContentTextProps } from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";

const DialogContentText = styled(
  ({
    id,
    subTitle,
    text,
    ...dialogContentTextProps
  }: { id: string; subTitle?: string; text?: React.ReactNode } & DialogContentTextProps) => (
    <MuiDialogContentText color="primary" variant="body2" id={id} {...dialogContentTextProps}>
      {subTitle && (
        <Typography component="span" display="block" color="primary" fontWeight={500}>
          {subTitle}
        </Typography>
      )}
      {text}
    </MuiDialogContentText>
  )
)(({ theme }) => ({
  textAlign: "center",
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  paddingTop: theme.spacing(3),
}));

export default DialogContentText;
