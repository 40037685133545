import * as Yup from "yup";

export const errorMessages = {
  newPassword: "Add your new password",
  confirmNewPassword: {
    required: "Confirm your new password",
    noMatch: "Does not match your new password",
  },
};

export const schema = Yup.object().shape({
  newPassword: Yup.string().max(50, "Too Long").min(6, "Too Short").required(errorMessages.newPassword),
  confirmNewPassword: Yup.string()
    .max(50, "Too Long")
    .min(6, "Too Short")
    .oneOf([Yup.ref("newPassword"), undefined], errorMessages.confirmNewPassword.noMatch)
    .required(errorMessages.confirmNewPassword.required),
});
