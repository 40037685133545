import { LeaderboardBase } from "types";
import { LeaderboardComparePeriod, LeaderboardTimePeriod } from "./LeaderboardEditable.types";

export const maxLeaderboards = 3;

export const leaderboardTimePeriodDay = {
  label: "Today",
  value: "Day" as const,
};
export const leaderboardTimePeriodWeek = {
  label: "Week",
  value: "Week" as const,
};
export const leaderboardTimePeriodMonth = {
  label: "Month",
  value: "Month" as const,
};
export const leaderboardTimePeriodAll = {
  label: "All Time",
  value: "All" as const,
};
export const leaderboardTimePeriods: LeaderboardTimePeriod[] = [
  leaderboardTimePeriodDay,
  leaderboardTimePeriodWeek,
  leaderboardTimePeriodMonth,
  leaderboardTimePeriodAll,
];

export const leaderboardComparePeriodWeek = {
  label: "Past Week",
  value: "Week" as const,
};
export const leaderboardComparePeriodMonth = {
  label: "Past Month",
  value: "Month" as const,
};
export const leaderboardComparePeriodYear = {
  label: "Past Year",
  value: "Year" as const,
};
export const leaderboardComparePeriodAll = {
  label: "All Time",
  value: "All" as const,
};
export const leaderboardComparePeriodCustom = {
  label: "Custom",
  value: "Custom" as const,
};

export const leaderboardComparePeriods: LeaderboardComparePeriod[] = [
  leaderboardComparePeriodWeek,
  leaderboardComparePeriodMonth,
  leaderboardComparePeriodYear,
  leaderboardComparePeriodAll,
  leaderboardComparePeriodCustom,
];

export const defaultLeaderboardTimePeriod = leaderboardTimePeriodDay;
export const defaultLeaderboardComparePeriod = leaderboardComparePeriodWeek;

export const leaderboardDefaults: LeaderboardBase = {
  name: "",
  exerciseId: "",
  metricId: "",
  groups: [],
  groupIds: [],
  type: defaultLeaderboardTimePeriod.value,
  endTime: null,
  variant: null,
  startTime: null,
  comparePeriodType: defaultLeaderboardComparePeriod.value,
  startDateComparePeriod: null,
};
