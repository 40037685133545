import amplitude from "amplitude-js";
import UserService from "../UserService";
import moment from "moment";
import { PROPERTIES, EVENTS } from "./AnalyticsService.config";

class AnalyticsService {
  static APPLICATION = "HUB_V1_WEB";

  static PROPERTIES = PROPERTIES;

  static EVENTS = EVENTS;

  static __instance;

  static get instance() {
    if (typeof AnalyticsService.__instance === "undefined") {
      AnalyticsService.init();
    }

    return AnalyticsService.__instance;
  }

  static async init() {
    if (!process.env.REACT_APP_AMPLITUDE_API_KEY) {
      return;
    }

    if (typeof AnalyticsService.__instance !== "undefined") {
      throw new Error("AnalyticsService has already been initialized");
    }

    AnalyticsService.__instance = amplitude.getInstance();
    AnalyticsService.__instance.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

    await AnalyticsService.setUserProperties();
  }

  static async setUserProperties() {
    const organisationId = await UserService.getOrganisationId();
    const { uid: userId, email } = UserService.currentUser;

    const now = moment().toISOString();

    AnalyticsService.instance.setUserId(userId);

    const identify = new AnalyticsService.instance.Identify()
      .setOnce(AnalyticsService.PROPERTIES.USER_PROPERTY_ORGANISATION_ID, organisationId)
      .set(AnalyticsService.PROPERTIES.USER_PROPERTY_EMAIL, email)
      .setOnce(AnalyticsService.PROPERTIES.USER_PROPERTY_FIRST_SEEN, now)
      .set(AnalyticsService.PROPERTIES.USER_PROPERTY_LAST_SEEN, now);

    AnalyticsService.instance.identify(identify);
  }

  static prepareEventProperties(otherEventProperties = {}) {
    const { EVENT_PROPERTY_APPLICATION } = AnalyticsService.PROPERTIES;

    return { [EVENT_PROPERTY_APPLICATION]: AnalyticsService.APPLICATION, ...otherEventProperties };
  }

  static clear() {
    AnalyticsService.__instance?.clearUserProperties();
    AnalyticsService.__instance = undefined;
  }

  static async logEvent(eventName, eventProperties) {
    if (!AnalyticsService.__instance) {
      /**
       * @description
       * Allows time for amplitude init to complete */
      await new Promise((resolve) => setTimeout(resolve, 200));
    }

    if (AnalyticsService.__instance) {
      AnalyticsService.instance.logEvent(eventName, AnalyticsService.prepareEventProperties(eventProperties));
    }

    try {
      if (process.env.REACT_APP_DEBUG_ANALYTICS && JSON.parse(process.env.REACT_APP_DEBUG_ANALYTICS)) {
        console.log(
          JSON.stringify(
            {
              "[EVENT]:": eventName,
              ...AnalyticsService.prepareEventProperties(eventProperties),
              analyticsInitialized: Boolean(AnalyticsService.__instance),
            },
            null,
            2
          )
        );
      }
    } catch {}
  }
}
export default AnalyticsService;
