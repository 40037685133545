import { styled } from "@mui/material";

const Span = styled("span")((props) => ({
  fontSize: "inherit",
  lineHeight: "inherit",
}));

export type SpanProps = typeof Span extends (props: infer P) => any ? P : never;

export default Span;
