import moment from "moment";
import {
  defaultLeaderboardComparePeriod,
  defaultLeaderboardTimePeriod,
  leaderboardComparePeriods,
  leaderboardTimePeriods,
} from "pages/Leaderboards/LeaderboardTables/LeaderboardEditable/LeaderboardEditable.config";
import {
  LeaderboardComparePeriod,
  LeaderboardFormValues,
  LeaderboardTimePeriod,
} from "pages/Leaderboards/LeaderboardTables/LeaderboardEditable/LeaderboardEditable.types";
import { LeaderboardBase, LeaderboardRankType } from "types/leaderboard";
import { Exercise, FireStoreMeasurement } from "types";
import { Group } from "types/groups";
import { DocumentData, QuerySnapshot } from "firebase/firestore";
import MeasurementService from "services/MeasurementService";

class LeaderboardHelpers {
  static toForm(
    leaderboard: LeaderboardBase,
    options: { groups: Group[]; exercises: Exercise[] }
  ): LeaderboardFormValues {
    const {
      name,
      groups,
      exerciseId,
      metricId,
      type,
      startDateComparePeriod,
      comparePeriodType,
      rankType,
      groupIds,
      ...restOfLeaderboard
    } = leaderboard;
    const exercise = (exerciseId && options.exercises.find(({ id }) => id === exerciseId)) || null;
    const metric = exercise?.metrics.find(({ field }) => field === metricId) || null;

    return {
      ...restOfLeaderboard,
      name,
      exercise,
      metric,
      variant: leaderboard.variant || exercise?.variants[0] || null,
      type: LeaderboardHelpers.getTimePeriod(type),
      startDateComparePeriod: comparePeriodType === "Custom" ? startDateComparePeriod : null,
      comparePeriodType: LeaderboardHelpers.getComparePeriod(comparePeriodType),
      rankType: rankType || LeaderboardRankType.Absolute,
      groups: options.groups.filter((group) => groupIds.includes(group.id)),
    };
  }

  static fromForm(leaderboard: LeaderboardFormValues): LeaderboardBase {
    const {
      exercise,
      metric,
      groups,
      type,
      variant,
      startDateComparePeriod,
      rankType: formRankType,
      comparePeriodType,
    } = leaderboard;

    if (!(exercise && metric)) {
      throw new Error("Exercise and Metric not set");
    }

    const rankType = formRankType || LeaderboardRankType.Absolute;

    return {
      name: leaderboard.name || LeaderboardHelpers.getDefaultName(leaderboard),
      exerciseId: exercise.id,
      metricId: metric.field,
      variant,
      /**
       * @deprecated In favour of groupIds
       * TODO: Remove */
      groups: groups.map(({ id, name, colour }) => ({ id, name, colour })),
      groupIds: groups.map(({ id }) => id),
      type: type.value,
      startDateComparePeriod:
        rankType === LeaderboardRankType.PercentageOfBest ? moment(startDateComparePeriod).toISOString() : null,
      rankType,
      comparePeriodType: rankType === LeaderboardRankType.PercentageOfBest ? comparePeriodType.value : null,
      endTime: null,
      startTime: null,
    };
  }

  static getTimePeriod(type: LeaderboardTimePeriod["value"] | "Custom") {
    return leaderboardTimePeriods.find((period) => period.value === type) || defaultLeaderboardTimePeriod;
  }

  static getComparePeriod(comparePeriodType: LeaderboardComparePeriod["value"] | null) {
    return (
      leaderboardComparePeriods.find((period) => period.value === comparePeriodType) || defaultLeaderboardComparePeriod
    );
  }

  static getDefaultName(leaderboard: LeaderboardFormValues) {
    return [leaderboard.exercise?.name, leaderboard.variant?.charAt(0), leaderboard.metric?.name]
      .filter(Boolean)
      .join(" | ");
  }

  static onSnapshot(
    snapshot: QuerySnapshot<DocumentData>,
    updateStore: (newAndUpdateMeasurements: { [id: string]: FireStoreMeasurement | undefined }) => void
  ) {
    const measurementUpdates: { [id: string]: FireStoreMeasurement | undefined } = {};

    snapshot.docChanges().forEach((docChange) => {
      if (docChange.type === "removed") {
        measurementUpdates[docChange.doc.id] = undefined;
        return;
      }

      const document = MeasurementService.deserializeFirestoreDoc(docChange.doc);

      measurementUpdates[docChange.doc.id] = document;
    });

    updateStore(measurementUpdates);
  }
}
export default LeaderboardHelpers;
