import { DateTime } from "./auth";
import { Variants } from "./exercise";

export enum LeaderboardRankType {
  Absolute = "Absolute",
  PercentageOfBest = "PercentageOfBest",
}

export interface Leaderboard {
  endTime: DateTime;
  exerciseId: string;
  variant: Variants | null;
  /**
   * @deprecated In favour of groupIds
   */
  groups: {
    id: string;
    colour: string;
    name: string;
  }[];
  groupIds: string[];
  id: string;
  metricId: string;
  name: string;
  startTime: DateTime;
  type: "Day" | "Week" | "Month" | "All" | "Custom";
  comparePeriodType: "Week" | "Month" | "Year" | "All" | "Custom" | null;
  rankType?: LeaderboardRankType;
  startDateComparePeriod: DateTime;
}

export interface LeaderboardBase extends Omit<Leaderboard, "id"> {}
