import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import MicrosoftButton, { MicrosoftIcon } from "./components/MicrosoftButton";
import Typography from "library/Typography";
import Div from "library/primitives/Div";
import { useCallback, useMemo, useState } from "react";
import useReauthentication from "components/ReauthenticationProvider/useReauthentication";
import UserService from "services/UserService";
import Grid from "@mui/material/Grid";
import { UserInfo } from "firebase/auth";
import Button from "components/Button";

export interface SettingsConnectedAccountsProps {}

function SettingsConnectedAccounts(props: SettingsConnectedAccountsProps) {
  const { t } = useTranslation();
  const reauthActions = useReauthentication();
  const [providers, setProviders] = useState<UserInfo[]>(UserService.currentUser!.providerData);

  const handleLinkMicrosoftAccount = useCallback(async () => {
    reauthActions.onStart({
      onSuccess: async () => {
        try {
          await UserService.linkMicrosoftSignIn(); //
          setProviders(UserService.currentUser!.providerData);
        } catch (error: any) {
          if (error?.code === "auth/multi-factor-auth-required") {
            /**
             * This is a HACK.
             *
             * If we don't implement this a user will be asked to re-authenticate with MFA.
             * But refreshing the page avoids this.
             * So:
             * - 1. It actually adds no security
             * - 2. It's a bad user experience to have to enter the MFA code twice anyway
             */
            window.location.reload();
          }
          throw error;
        }
      },
    });
  }, [reauthActions]);

  const handleUnlinkMicrosoftAccount = useCallback(async () => {
    reauthActions.onStart({
      onSuccess: async () => {
        await UserService.unlinkMicrosoftSignIn();
        setProviders(UserService.currentUser!.providerData);
      },
    });
  }, [reauthActions]);

  const microsoftProvider = useMemo(
    () => providers.find(({ providerId }) => providerId === "microsoft.com"),
    [providers]
  );

  return (
    <Stack justifyContent="flex-start">
      <Div sx={{ mb: 2 }}>
        <Typography variant="TextL" fontWeight={500}>
          {t("SettingsConnectedAccounts.title")}
        </Typography>
      </Div>
      <Div>
        {!microsoftProvider ? (
          <MicrosoftButton onClick={handleLinkMicrosoftAccount}>
            {t("SettingsConnectedAccounts.linkMicrosoftButtonText")}
          </MicrosoftButton>
        ) : (
          <Grid container gap={1} alignItems="center">
            <Grid item>
              <Stack>
                <Grid container gap={1} alignItems="center">
                  <Grid item lineHeight={0}>
                    <MicrosoftIcon />
                  </Grid>
                  <Grid item xs>
                    {t("SettingsConnectedAccounts.microsoftAccount")}
                  </Grid>
                  <Grid item xs={12}>
                    {microsoftProvider.email}
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item>
              <Button size="small" variant="tertiary" type="reset" onClick={handleUnlinkMicrosoftAccount} fullWidth>
                {t("SettingsConnectedAccounts.unlinkButtonText")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Div>
    </Stack>
  );
}

export default SettingsConnectedAccounts;
