import { createContext } from "react";

export interface ReauthentiationFlowOptions {
  onSuccess: () => Promise<void> | void;
  onError?: (error?: any) => void;
  onCancel?: () => void;
}

export type ReauthenticationContextType = {
  onStart: (opts: ReauthentiationFlowOptions) => void;
  onCancel: () => void;
};

const ReauthenticationContext = createContext<ReauthenticationContextType | undefined>(undefined);
ReauthenticationContext.displayName = "ReauthenticationContext";

export default ReauthenticationContext;
