import { TextFieldProps } from "@mui/material";
import { useField } from "formik";
import TextField from "../TextField";

function FormText({ name, ...props }: TextFieldProps & { name: string }) {
  const [field, meta, helpers] = useField(name);

  const fieldProps = {
    name,
    onChange: (event: any) => helpers.setValue(event.target.value),
    value: field.value || "",
    onBlur: () => helpers.setTouched(true),
    error: !!Boolean(meta.touched && meta.error) || props.error,
    helperText: meta.touched && meta.error ? meta.error : props.helperText,
  };

  return <TextField {...props} {...fieldProps} />;
}

export default FormText;
