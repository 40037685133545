import useDefinedContext from "hooks/useDefinedContext";
import ReauthenticationContext from "../contexts/ReauthenticationContext";

interface UseReauthenticationContextProps {}

function useReauthentication(props?: UseReauthenticationContextProps) {
  return useDefinedContext(ReauthenticationContext);
}

export default useReauthentication;
