import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardProvider from "./providers/DashboardProvider";
import DashboardOptionsProvider from "./providers/DashboardOptionsProvider";
import LoadingFallback from "./DashboardsHome/DashboardWidgetFactory/components/WidgetStatusHandler/LoadingFallback";
import { useTranslation } from "react-i18next";
import ThemeProvider2024 from "library/MuiThemeProvider/ThemeProvider2024";

const DashboardsHome = lazy(() => import("./DashboardsHome"));

export interface DashboardsProps {}

function Dashboards(props: DashboardsProps) {
  const { t } = useTranslation();
  return (
    // TODO: ThemeProvider should be moved to the root of the app. We are using it here to avoid breaking changes in the existing codebase.
    <ThemeProvider2024>
      <LocalisedLoadingProvider>
        <DashboardProvider>
          <DashboardOptionsProvider>
            <Routes>
              <Route
                path={"*"}
                element={
                  <Suspense fallback={<LoadingFallback animation="pulse" message={t("Dashboards.loadingMessage")} />}>
                    <DashboardsHome />
                  </Suspense>
                }
              />
            </Routes>
          </DashboardOptionsProvider>
        </DashboardProvider>
      </LocalisedLoadingProvider>
    </ThemeProvider2024>
  );
}

export default Dashboards;
