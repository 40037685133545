/**
 * Design System Component: TextField
 */

import MuiTextField from "@mui/material/TextField";
import type { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/system";

/**
 * @description TextField component has label shrunk by default.
 */
const TextField = styled((props: TextFieldProps) => {
  const { variant = "standard", InputLabelProps, ...rest } = props;

  return <MuiTextField variant={variant} InputLabelProps={{ shrink: true, ...InputLabelProps }} {...rest} />;
})(({ theme, ...props }) => ({
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled": {
      opacity: 0.5,
    },
  },

  "& .MuiInput-input:focus": {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
  },

  "& .MuiFormLabel-root": {
    color: props.error ? theme.palette.error.main : theme.palette.grey[100],
  },

  "& .MuiInput-input": {
    fontSize: "16px",
    lineHeight: "22px",
  },

  "& .MuiSelect-icon": {
    color: theme.palette.primary.main,
  },

  "& label + .MuiInput-root": {
    marginTop: "24px",
  },

  "& .MuiFormHelperText-root": {
    color: props.error ? theme.palette.error.main : theme.palette.grey[100],
    fontSize: "12px",
    lineHeight: "18px",
  },

  "& .MuiInputBase-root": {
    "&.Mui-disabled": {
      opacity: 0.4,
    },
    "&.MuiInput-underline.Mui-disabled::before": {
      borderBottomStyle: "solid",
    },
    "& .MuiSelect-select": {
      padding: 0,
      paddingTop: "1px",
      paddingBottom: "2px",
    },
  },
}));

export default TextField;
