import { DotContainer, Dot } from "./components/LoaderDotsContainer";

export interface LoaderDotsProps {
  size?: "small" | "medium" | "large";
}

function LoaderDots(props: LoaderDotsProps) {
  const { size = "medium" } = props;

  return (
    <DotContainer size={size} data-testid="loader">
      <Dot data-testid="loader-dot" />
      <Dot data-testid="loader-dot" />
      <Dot data-testid="loader-dot" />
    </DotContainer>
  );
}

export default LoaderDots;
