import { useReducer } from "react";
import { Dashboard, DashboardTile } from "types/Dashboard";

export enum DashboardActionType {
  ADD_ALL = "ADD_ALL",
  CLEAR_ALL = "CLEAR_ALL",
  ADD_ONE = "ADD_ONE",
  UPDATE_ONE = "UPDATE_ONE",
  UPDATE_VIEW = "UPDATE_VIEW",
  DELETE_ONE = "DELETE_ONE",
  REVERT_ONE = "REVERT_ONE",
  ADD_TILE = "ADD_TILE",
  DUPLICATE_TILE = "DUPLICATE_TILE",
  UPDATE_TILE = "UPDATE_TILE",
  DELETE_TILE = "DELETE_TILE",
}
interface AddAllAction {
  type: DashboardActionType.ADD_ALL;
  payload: Dashboard[];
}
interface ClearAllAction {
  type: DashboardActionType.CLEAR_ALL;
  payload?: undefined;
}
interface AddOneAction {
  type: DashboardActionType.ADD_ONE;
  payload: Dashboard;
}
interface UpdateOneAction {
  type: DashboardActionType.UPDATE_ONE;
  payload: Partial<Dashboard>;
}

interface UpdateViewAction {
  type: DashboardActionType.UPDATE_VIEW;
  payload: Pick<Dashboard, "id" | "isGlobal">;
}
interface DeleteOneAction {
  type: DashboardActionType.DELETE_ONE;
  payload: Pick<Dashboard, "id">;
}
interface RevertOneAction {
  type: DashboardActionType.REVERT_ONE;
  payload: Dashboard;
}
interface AddTileAction {
  type: DashboardActionType.ADD_TILE;
  payload: DashboardTile;
  dashboardId: string;
}
interface DuplicateTileAction {
  type: DashboardActionType.DUPLICATE_TILE;
  payload: DashboardTile;
  dashboardId: string;
  tileId: string;
}
interface UpdateTileAction {
  type: DashboardActionType.UPDATE_TILE;
  payload: Partial<DashboardTile>;
  dashboardId: string;
}
interface DeleteTileAction {
  type: DashboardActionType.DELETE_TILE;
  payload: Pick<DashboardTile, "id">;
  dashboardId: string;
}

export type DashboardReducerActions =
  | undefined
  | AddAllAction
  | ClearAllAction
  | AddOneAction
  | UpdateOneAction
  | UpdateViewAction
  | DeleteOneAction
  | RevertOneAction
  | AddTileAction
  | DuplicateTileAction
  | UpdateTileAction
  | DeleteTileAction;

function dashboardsReducer(state: Dashboard[], action: DashboardReducerActions): Dashboard[] {
  switch (action?.type) {
    case DashboardActionType.ADD_ALL: {
      return action.payload;
    }
    case DashboardActionType.CLEAR_ALL: {
      return [];
    }
    case DashboardActionType.ADD_ONE: {
      return [...state.filter((dashboard) => dashboard.id !== action.payload.id), action.payload];
    }
    case DashboardActionType.UPDATE_ONE: {
      return state.map((dashboard) => {
        if (dashboard.id === action.payload.id) {
          return { ...dashboard, ...action.payload };
        }

        return dashboard;
      });
    }
    case DashboardActionType.UPDATE_VIEW: {
      return state.map((dashboard) => {
        if (dashboard.id === action.payload.id) {
          return { ...dashboard, isGlobal: action.payload.isGlobal };
        }

        return dashboard;
      });
    }
    case DashboardActionType.DELETE_ONE: {
      return state.filter((dashboard) => dashboard.id !== action.payload.id);
    }
    case DashboardActionType.REVERT_ONE: {
      return [...state.filter((dashboard) => dashboard.id !== action.payload.id), action.payload];
    }
    case DashboardActionType.ADD_TILE: {
      return state.map((dashboard) => {
        if (dashboard.id === action.dashboardId) {
          return {
            ...dashboard,
            tiles: [...dashboard.tiles, action.payload],
          };
        }
        return dashboard;
      });
    }
    case DashboardActionType.DUPLICATE_TILE: {
      return state.map((dashboard) => {
        if (dashboard.id === action.dashboardId) {
          // add tile after the duplicated tile
          const tiles = dashboard.tiles.reduce((acc, tile) => {
            if (tile.id === action.tileId) {
              return [...acc, tile, action.payload];
            }
            return [...acc, tile];
          }, [] as DashboardTile[]);

          return { ...dashboard, tiles: tiles };
        }
        return dashboard;
      });
    }
    case DashboardActionType.UPDATE_TILE: {
      return state.map((dashboard) => {
        if (dashboard.id === action.dashboardId) {
          return {
            ...dashboard,
            tiles: dashboard.tiles.map((tile) => {
              if (tile.id === action.payload.id) {
                return { ...tile, ...action.payload };
              }
              return tile;
            }),
          };
        }
        return dashboard;
      });
    }
    case DashboardActionType.DELETE_TILE: {
      return state.map((dashboard) => {
        if (dashboard.id === action.dashboardId) {
          return {
            ...dashboard,
            tiles: dashboard.tiles.filter((tile) => tile.id !== action.payload.id),
          };
        }
        return dashboard;
      });
    }
  }

  return state;
}

function useDashboardsReducer(initialValue: Dashboard[] = []) {
  return useReducer(dashboardsReducer, initialValue);
}

export default useDashboardsReducer;
