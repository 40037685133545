import { styled } from "@mui/material/styles";

const sizesFactory = {
  small: "6px",
  medium: "8px",
  large: "10px",
};
interface DotContainerProps {
  size: "small" | "medium" | "large";
}

export const DotContainer = styled("span")<DotContainerProps>((props) => ({
  "& span": {
    height: sizesFactory[props.size],
    width: sizesFactory[props.size],
    margin: "2px",
  },
}));

export const Dot = styled("span")(({ theme }) => ({
  animation: "dot 1.2s infinite linear",
  backgroundColor: theme.palette.primary.main,
  display: "inline-flex",
  borderRadius: "50%",

  "&:nth-of-type(2)": { animationDelay: "200ms" },
  "&:nth-of-type(3)": { animationDelay: "400ms" },

  "@keyframes dot": {
    "0%": { transform: "scale(0.1)" },
    "30%": { transform: "scale(1.2)" },
    "60%, 100%": { transform: "scale(0)" },
  },
}));
